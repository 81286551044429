import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MetaMaskProvider } from "metamask-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MetaMaskProvider>
    <React.StrictMode>
      <ToastContainer theme="dark" position="top-center" />
      <App />
    </React.StrictMode>
  </MetaMaskProvider>
);
