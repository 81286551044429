import FlexColumn from "./FlexColumn";
import TextDescription from "./SectionDescription";
import TextTitle from "./SectionTititle";
import paintImage from "../assets/images/paint.png"
import App from "./Paint"

const DrawSection =()=>{
    return (
        <section className="pt-10 pb-32 w-full px-6 lg:px-0">
            <FlexColumn className={'gap-3 items-center justify-center'}>
                <TextTitle title="Draw your own memes" color="[#03191E]" position="center" />
                <TextDescription color="[#03191E]" title="Share them on twitter with $PAINT and/or @PaintonETH for a chance to get a airdrop!!" />
            </FlexColumn>
            <div className="flex flex-col mt-8 mx-auto items-center justify-center w-full">
                <App />
            </div>
           
        </section>
    )
}

export default DrawSection;