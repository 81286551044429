import { BrowserView, MobileView } from "react-device-detect";
import React from "react";
import useSound from "use-sound";
import clickSFX from "./sounds/click.mp3";
import Header from "./components/Header";
import TopSection from "./components/TopSection";
import TokenSection from "./components/TokenSection";
import Footer from "./components/Footer";
import RoadMapSection from "./components/RoadMapSection";
import DrawSection from "./components/DrawSection";

function App() {
  const [click] = useSound(clickSFX);
  return (
    <div className="w-full h-full" onClick={click}>
      <div className="w-full relative" id="home">
        <div className="w-full h-[300px] absolute bottom-0">
          <div className="flick"></div>
        </div>
          <Header />
          <TopSection />
        </div>
        <TokenSection />
        <DrawSection />
        <Footer />
    </div>
  );
}

export default App;
