import FlexBetween from "../components/FlexBetween";
import FlexCenter from "../components/FlexCenter";
import logo from "../assets/images/logo.png";
import telegram from "../assets/images/telegram.png";
import twitter from "../assets/images/twitter.png";
import { useEffect, useState } from "react";
import menuImage from "../assets/images/menu.svg";
import closeImage from "../assets/images/close.svg";
import lSzZU3 from "../assets/images/6lSzZU3.png"

const Header = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [open]);
  return (
    <FlexBetween className={"absolute  w-full p-6 lg:pr-[78px] lg:pl-[42px] lg:py-11"}>
      <div className="flex items-center">
        <img src={lSzZU3} className="w-12 h-12" />
        <div style={{ fontFamily: "MSPAINT" }} className="text-2xl font-bold">
          $PAINT
        </div>
      </div>
      <FlexCenter className={"hidden lg:flex gap-[88px]"}>
        <a style={{ fontFamily: "MSPAINT" }} className="text-white text-xl font-ComicSansMS" href="#home">
          Home
        </a>
        <a style={{ fontFamily: "MSPAINT" }} className="text-white text-xl font-ComicSansMS" href="#tokenomics">
          Tokenomics
        </a>
        <a style={{ fontFamily: "MSPAINT" }} className="text-white text-xl font-ComicSansMS" href="#draw">
          PAINT
        </a>
      </FlexCenter>
      <div className="flex items-center gap-2">
        <a href="https://t.me/paintonsolana" target="_blank">
          <img src="https://cdn-icons-png.flaticon.com/512/124/124019.png" className="w-10 h-10 rounded-md" />
        </a>
        <a href="https://dexscreener.com/solana/6LeaoRjJvL2uD2oTcjNDgdE5GFh8yy8oB6CoMVLh8Do8" target="_blank">
          <img src="https://dexscreener.com/icon-512x512.png" className="w-10 h-10 rounded-md" />
        </a>
        <a href="https://twitter.com/Paintonsolana" target="_blank">
          <img src="https://seeklogo.com/images/T/twitter-icon-square-logo-108D17D373-seeklogo.com.png" className="w-10 h-10 rounded-md" />
        </a>
      </div>
      <img alt="menu" className="flex lg:hidden" onClick={() => setOpen(true)} src={menuImage} />
      <div className={`absolute bg-gradient-color flex-col items-center justify-center pt-10 h-screen z-[100] w-full top-0 left-0 gap-3 ${open ? "flex" : "hidden"}`}>
        <div className="ml-auto">
          <img alt="close" className="flex absolute top-6 right-6" onClick={() => setOpen(false)} src={closeImage} />
        </div>
        <a style={{fontFamily: "MSPAINT"}} className="text-white text-xl font-ComicSansMS" href="#home" onClick={() => setOpen(false)}>
          Home
        </a>
        <a style={{fontFamily: "MSPAINT"}} className="text-white text-xl font-ComicSansMS" href="#tokenomics" onClick={() => setOpen(false)}>
          Tokenomics
        </a>
        <a style={{fontFamily: "MSPAINT"}} className="text-white text-xl font-ComicSansMS" href="#roadmap" onClick={() => setOpen(false)}>
          Roadmap
        </a>
        <FlexCenter className={"gap-3"}>
          <a href="https://t.me/paintonsolana" target="_blank" onClick={() => setOpen(false)}>
            <img alt="social-telegram" src={telegram} />
          </a>
          <a href="https://twitter.com/Paintonsolana" target="_blank" onClick={() => setOpen(false)}>
            <img alt="social-twitter" src={twitter} />
          </a>
        </FlexCenter>
      </div>
    </FlexBetween>
  );
};

export default Header;
