import React, { useEffect, useState } from "react";
import "./Header.css";
import CanvasDraw from "react-canvas-draw";
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
          setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};
function App() {
  const [utensil, setUtensil] = useState({
    tool: "brush",
    weight: 5,
    color: "black",
  });

  function handleUtensil(updateItem, keyHolder) {
    const newUtensil = { ...utensil };
    if (updateItem === "eraser") {
      newUtensil["color"] = "white";
      newUtensil["tool"] = "brush";
      setUtensil(newUtensil);
    } else {
      newUtensil[keyHolder] = updateItem.toLowerCase();
      setUtensil(newUtensil);
    }
    console.log(newUtensil);
  }

  const colorList = ["red", "blue", "green", "yellow", "pink", "black", "white", "brown", "purple", "grey", "orange"];

  const ColorCard = ({ color, handleUtensil }) => <div onClick={() => handleUtensil(color, "color")} className="color-icon" style={{ backgroundColor: `${color}` }}></div>;

  const ColorContainer = ({ handleUtensil }) => {
    const colorGrid = colorList.map((color) => <ColorCard handleUtensil={handleUtensil} key={color} color={color} />);
    return <div id="color-grid">{colorGrid}</div>;
  };

  const Tools = ({ handleUtensil }) => (
    <div style={{ backgroundColor: "lightgrey", padding: "5px", width: "120px", textAlign: "center" }}>
      <button name="brush" className="tool-icon" onClick={(event) => handleUtensil(event.target.name, "tool")}>
        ✏️
      </button>
      <button name="bucket" className="tool-icon" onClick={(event) => handleUtensil(event.target.name, "tool")}>
        🥣
      </button>
      <button name="eraser" className="tool-icon" onClick={(event) => handleUtensil(event.target.name, "tool")}>
        🧼
      </button>
    </div>
  );

  const Container = ({ utensil }) => {
    const { tool, weight, color } = utensil;

    const [magic, setMagic] = useState({
      width: "50%",
      height: "600px",
      backgroundColor: "white",
      border: "5px solid rgb(207, 207, 207)",
      borderStyle: "groove",
    });

    const [draw, setDraw] = useState(false);

    function handleBucket() {
      if (tool === "bucket") {
        const newItem = {
          ...magic,
          backgroundColor: color,
        };
        setMagic(newItem);
      }
    }

    function handleMouseDown(event) {
      if (tool === "brush" && draw === true) {
        console.log(event.screenX, event.screenY);
      }
    }

    return <div style={magic} onMouseDown={() => setDraw(true)} onMouseUp={() => setDraw(false)} onClick={handleBucket} onMouseMove={(event) => handleMouseDown(event)}></div>;
  };

  useEffect(() => {
    console.log(utensil);
  }, [utensil]);
  const mdphone = useMediaQuery("(max-width: 768px)")
  const Phone = useMediaQuery("(max-width: 500px)")

  return (
    <>
      <div id="top-page" className="flex items-center border border-x-black border-t-black">
        <span>🎨 untitled - Paint</span>
        <span>🔴</span>
      </div>
      <div id="header" className="border border-x-black">
        <Tools handleUtensil={handleUtensil} />
        <select
          onChange={(event) => {
            console.log(event.target.value);
            if (event.target.value == "Thin") {
              handleUtensil(event.target.value, "weight");
              const newUtensil = { ...utensil };
              newUtensil["weight"] = 5;
              setUtensil(newUtensil);
            }
            if (event.target.value == "Normal") {
              handleUtensil(event.target.value, "weight");
              const newUtensil = { ...utensil };
              newUtensil["weight"] = 10;
              setUtensil(newUtensil);
            }
            if (event.target.value == "Thick") {
              handleUtensil(event.target.value, "weight");

              const newUtensil = { ...utensil };
              newUtensil["weight"] = 15;
              setUtensil(newUtensil);
            }
          }}
          id="brush-detail"
        >
          <option>Thin</option>
          <option>Normal</option>
          <option>Thick</option>
        </select>
        <ColorContainer handleUtensil={handleUtensil}/>
      </div>
      <CanvasDraw 
        className="border border-x-black border-b-black rounded-b-lg w-full"  
        brushRadius={utensil.weight} 
        canvasHeight={500}
        canvasWidth={Phone?400:mdphone?560:800}
        brushColor={utensil.color} />
    </>
  );
}

export default App;
