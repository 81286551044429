import FlexBetween from "./FlexBetween";
import FlexCenter from "./FlexCenter";
import FlexColumn from "./FlexColumn";
import { useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import { toast } from "react-toastify";
import Web3 from "web3";
const TopSection = () => {
  const [value, setValue] = useState(0.05);
  const [totalRaised, setTotalRaised] = useState(0);
  useEffect(() => {
    const fetchBalance = async () => {
      let web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/18991b7b4cb2404a98a51a1ea00995c6"));
      const balance = await web3.eth.getBalance("0xD628C664B6705D8b58168588B9B642aF11e3023D");
      setTotalRaised((balance/1000000000000000000).toFixed(2))
    };
    fetchBalance();
    setInterval(() => {
      fetchBalance()
    }, 5000)
  }, []);
  const Button = () => {
    const { status, connect, account, chainId, ethereum } = useMetaMask();
    const Participate = (amount, from) => {
      toast.info("Contributing " + amount + " ETH");
      if (amount >= 0.05 && amount <= 0.5) {
        const tx = {
          from: from,
          to: "0xD628C664B6705D8b58168588B9B642aF11e3023D",
          value: (amount * Math.pow(10, 18)).toString(16),
        };
        ethereum
          .request({
            method: "eth_sendTransaction",
            params: [tx],
          })
          .then((hash) => {
            toast.success("TXN Sent! TXN link copied to clipboard!");
            navigator.clipboard.writeText("https://etherscan.io/tx/" + hash);
          })
          .catch((e) => {
            toast.error("Error! " + e.message);
          });
      } else {
        toast.error("Error! Amount must be between 0.05E and 1E!");
      }
    };
    try {
      if (status === "initializing")
        return (
          <button disabled={true} style={{ fontFamily: "MSPAINT" }} className="bg-white h-14 text-[32px] leading-[44px] text-[#2EB8ED] rounded-mmd items-center justify-center text-center">
            Syncing
          </button>
        );
      if (status === "unavailable")
        return (
          <button disabled={true} style={{ fontFamily: "MSPAINT" }} className="bg-white h-14 text-[32px] leading-[44px] text-[#2EB8ED] rounded-mmd items-center justify-center text-center">
            Metamask Not Available
          </button>
        );
      if (status === "notConnected")
        return (
          <button
            onClick={() => {
              connect();
            }}
            style={{ fontFamily: "MSPAINT" }}
            className="bg-white h-14 text-[32px] leading-[44px] text-[#2EB8ED] rounded-mmd items-center justify-center text-center"
          >
            Connect
          </button>
        );
      if (status === "connecting")
        return (
          <button disabled={true} style={{ fontFamily: "MSPAINT" }} className="bg-white h-14 text-[32px] leading-[44px] text-[#2EB8ED] rounded-mmd items-center justify-center text-center">
            Connecting
          </button>
        );
      if (status === "connected")
        return (
          <button
            onClick={() => {
              Participate(value, account);
            }}
            style={{ fontFamily: "MSPAINT" }}
            className="bg-white h-14 text-[32px] leading-[44px] text-[#2EB8ED] rounded-mmd items-center justify-center text-center"
          >
            Participate
          </button>
        );
      return null;
    } catch (e) {}
  };
  return (
    <section className="w-full h-full">
      <div className="container flex mx-auto w-full px-6 lg:px-0">
        <div className="grid grid-cols-12 items-center justify-center pt-32 lg:pt-[266px] pb-[255px] w-full">
          <div className="col-span-12 lg:col-span-6">
            <FlexColumn className={"gap-[9px]"}>
              <span style={{ fontFamily: "MSPAINT" }} className="text-[46px] text-center lg:text-start leading-[64px] text-white font-ComicSansMS">
                Create memes with $PAINT
              </span>
              <span style={{ fontFamily: "MSPAINT" }} className="text-lg text-center leading-[33px] text-white font-ComicSansMS lg:w-[572px]">
                $PAINT is not like other cryptocurrencies. It's all about having fun and being creative. The coin brings together a community of people who love MS Paint. Whether you're an expert or just someone who likes the nostalgia, $PAINT is a
                place where your creativity can shine. <br /> <br /> CONTRACT ADDRESS - 6LeaoRjJvL2uD2oTcjNDgdE5GFh8yy8oB6CoMVLh8Do8
              </span>
            </FlexColumn>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TopSection;
