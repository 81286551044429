import tokenImage from "../assets/images/tokenomics.jpg";
import TextDescription from "./SectionDescription";
import TextTitle from "./SectionTititle";
const TokenSection = () => {
  return (
    <section className="w-full container flex flex-col gap-[25px] pt-2 mx-auto mt-10" id="tokenomics">
      <div style={{ fontFamily: "MSPAINT" }} className="text-center lg:text-center text-[46px] leading-[64px] text-[#03191E] font-ComicSansMS">
        Tokenomics
      </div>
      <div className="grid grid-cols-2  items-center gap-[72px] w-full px-6 lg:px-0">
        <div className="col-span-2 lg:col-span-1">
          <img alt="token" src="https://media.discordapp.net/attachments/1208249048096833546/1208897868287713360/Screenshot_2024-02-18_at_5.08.26_PM.png?ex=6632bd60&is=66316be0&hm=ec263708237888ec72b33669e311fed9eac0d0b9e990716882bd1d514c2c7444&=&format=webp&quality=lossless&width=1034&height=813" />
        </div>
        <div className="col-span-2 lg:col-span-1 flex flex-col gap-10">
          <div style={{fontFamily: "MSPAINT"}} className="text-lg leading-[33px] text-black font-ComicSansMS">$PAINT is coming soon with a stealth launch on SOL, and will have sniper mitigation tactics in place.</div>
          <div style={{fontFamily: "MSPAINT"}} className="text-lg leading-[33px] text-black font-ComicSansMS">The total supply of $PAINT is 10,000,000 tokens. 85% to LP, 10% for future CEX listings, and marketing purposesm and 5% for team.</div>
        </div>
      </div>
    </section>
  );
};

export default TokenSection;
