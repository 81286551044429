import FlexBetween from "./FlexBetween";
import FlexColumn from "./FlexColumn";
import telegram from "../assets/images/icons-telegram.png";
import twitter from "../assets/images/icons8-twitter.png";
import logo from "../assets/images/footer-logo.png";
import lSzZU3 from "../assets/images/6lSzZU3.png"

import FlexCenter from "./FlexCenter";
const Footer = () => {
  return (
    <section className=" bg-gradient-color w-full px-6 lg:px-0">
      <FlexBetween className={"container flex flex-col lg:flex-row items-center  mx-auto py-10"}>
        <FlexColumn className={"gap-[10px] mb-14 lg:mb-0"}>
          <span style={{ fontFamily: "MSPAINT" }} className="text-4xl lg:text-[40px] lg:leading-[87px] text-white font-ComicSansMS">
            Join the Paint fam
          </span>
          <FlexCenter className={"gap-5 lg:gap-12"}>
            <a href="https://t.me/paintonsolana" rel="noreferrer" target="_blank">
              <img alt="social-telegram" src={telegram} className="w-6 lg:w-12" />
            </a>
            <a href="https://twitter.com/Paintonsolana" rel="noreferrer" target="_blank">
              <img alt="social-twitter" src={twitter} className="w-6 lg:w-12" />
            </a>
          </FlexCenter>
        </FlexColumn>
        <div className="flex items-center">
          <img src={lSzZU3} className="w-24 h-24" />
          <div style={{ fontFamily: "MSPAINT" }} className="text-5xl">
            $PAINT
          </div>
        </div>
      </FlexBetween>
    </section>
  );
};

export default Footer;
